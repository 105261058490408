<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title> Выберете операцию </template>
      <v-autocomplete outlined :items="list" item-text="text" item-value="value" return-object v-model="operation" :label="$t(model.title)" hide-details dense />
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn @click="next()" :disabled="!operation">Далее</v-btn>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getAccess, modelOptions } from "@/components/mixings";

export default {
  components: {},
  mixins: [getAccess, modelOptions],
  props: {
    value: Boolean,
    operations: { type: Array, default: [] },
  },
  data() {
    return {
      model: {
        name: "operation_type",
        title: "",
        type: "select",
        dir: "operationTable",
        dirFilter: { value: this.operations },
      },
      operation: null,
      m_out: this.$store.getters["config/get"].models.cashOut.config,
      m_in: this.$store.getters["config/get"].models.cashIn.config,
    };
  },
  computed: {
    listOperation() {
      let res = [];
      for (let i = 0; i < this.m_in.length; i++) {
        const el = this.m_in[i];
        if (el?.disabled !== true) res.push(el.id);
      }
      for (let i = 0; i < this.m_out.length; i++) {
        const el = this.m_out[i];
        if (el?.disabled !== true) res.push(el.id);
      }
      return res;
    },
    list() {
      let res = this.getOptions(this.model).sort((a, b) => {
        return (a.text > b.text) - (a.text < b.text);
      });
      res = res.filter((e) => this.listOperation.includes(e.value));

      return res;
    },
  },
  created() {},
  watch: {},
  methods: {
    next() {
      if (this.operation) this.$emit("select", this.operation);
      this.$emit("input");
    },
  },
};
</script>
